import { module as modular } from 'modujs';

export default class extends modular {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                btn: 'togglefilters',
                reset: 'reset',
                select: 'select',
                open: 'toggleSidebar',
                resetall: 'resetall',
                refresh: 'refresh',
                pagination: 'pagination',
                changeMonth: 'refreshMonth',
                reset: 'reset',
                checked: 'checked',
            },
            change: {
                change: 'change',
            },
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
        if (this.getData('init')) {
            setTimeout(() => {
                this.refresh();
            }, 500);
        }
    }

    // refresh(e) {
    //     const target = e.currentTarget;
    //     if($(target).attr('type') == 'checkbox') {
    //         $(target).parent('label').toggleClass('is-active');
    //     }

    //     let form = $(this.el);
    //     let t = this;
    //     jQuery.ajax({
    //         url: this.ajaxurl,
    //         type: 'POST',
    //         data: form.serialize(),
    //         success: function (response) {
    //             if ($('.b-relation_listing').length) {
    //                 $('.b-relation_listing').html(response.content);
    //             }
    //             // setTimeout(() => {
    //             //     t.call('update', false, 'Scroll'); // update Locomotive Scroll
    //             //     t.call('scrollTo', '.b-relation_listing', 'Scroll');
    //             // }, 500);
    //         },
    //     });
    // }

    refresh() {
        let form = $(this.el);
        let t = this;

        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            beforeSend: function() {
                if (form.find('.b-relation_listing').length) {
                    form.find('.b-relation_listing').html('<p>Recherche en cours ....</p>');
                }
                if (form.find('.b-relation_pagination').length) {
                    form.find('.b-relation_pagination').html('');
                }
            },
            success: function (response) {
                
                if (form.find('.b-relation_listing').length) {
                    form.find('.b-relation_listing').html(response.content);
                }

                if (form.find('.b-relation_pagination').length) {
                    form.find('.b-relation_pagination').html(response.pagination);
                }
                setTimeout(() => {
                    t.call('update', false, 'Scroll'); // update Locomotive Scroll
                    // t.call('scrollTo', '.b-relation_listing', 'Scroll');
                }, 500);
            },
        });
    }

    checked(e) {
        const target = e.currentTarget;
        const items = target;
        let check = false;

        for (let i = 0; i < 4; i++) {
            if (items[i].classList.contains('is-active')) {
                items[i].classList.remove('is-active');
                check == true;
            }
        }

        if (check !== true) {
            item.classList.add('-checked');
        }
    }

    pagination(e) {
        const el = e.currentTarget;
        let paged = el.getAttribute('data-paged');
        let form = $(this.el);
        $(form).find('input[name="paged"]').val(paged);

        this.refresh();
    }

    change(e) {
        const target = e.currentTarget;
        if($(target).attr('type') == 'checkbox') {
            $(target).parent('label').toggleClass('is-active');
        }

        let form = $(this.el);
        $(form).find('input[name="paged"]').val(1);

        this.refresh();
    }
}
